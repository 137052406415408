// countryWeightInput.jsx
import React from 'react';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { Colors } from '../../../utils/budget-tool-specific';

const CountryWeightInput = ({ weight, onChange }) => {
  return (
    <WeightContainer>
      <WeightLabel>
        <span>Priority Weight:</span>
        <StyledInput
          type="number"
          min="0"
          max="100"
          value={weight}
          onChange={(e) => onChange(parseFloat(e.target.value))}
          onClick={e => e.stopPropagation()}
        />
      </WeightLabel>
      <WeightHelper>Higher weight increases importance in passport comparisons</WeightHelper>
    </WeightContainer>
  );
};

const WeightContainer = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid ${Colors.primaryAccent}40;
  margin-bottom: 15px;
`;

const WeightLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  color: ${Colors.primaryAccent};
  font-size: 14px;
  font-weight: 500;
`;

const StyledInput = styled(Input)`
  width: 80px;
  height: 32px;
  background: transparent;
  border: 1px solid ${Colors.primaryAccent}80;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 4px 8px;
  
  &:focus {
    background: ${Colors.primaryLight}20;
    border-color: ${Colors.primaryAccent};
    color: white;
    box-shadow: 0 0 0 1px ${Colors.primaryAccent}40;
  }
  
  &::-webkit-inner-spin-button, 
  &::-webkit-outer-spin-button {
    opacity: 1;
    background: transparent;
  }
`;

const WeightHelper = styled.div`
  color: ${Colors.primaryLight};
  font-size: 12px;
  margin-top: 4px;
  opacity: 0.7;
`;

export default CountryWeightInput;