// countryCard.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PinkButtonContainer, EditIcon } from '../../../utils/budget-tool-specific';
import { 
    CardContainer, 
    CardContent, 
    CardTitle, 
    ExpandedContent,
    ButtonContainer,
    NotesSection,
    NotesTextArea
} from './commonComponents';
import PassportList from './passportList';
import CountryWeightInput from './countryWeightInput';

const CountryCard = ({ 
    country, 
    isExpanded, 
    passports, 
    onToggleExpand, 
    onDelete, 
    onUpdate,
    onPassportSelect,
    onPassportRemove 
}) => {
    const handleCardClick = () => {
        onToggleExpand(isExpanded ? null : country.id);
    };

    return (
        <CardContainer 
            onClick={handleCardClick}
            isExpanded={isExpanded}
        >
            <CardContent onClick={e => isExpanded && e.stopPropagation()}>
                <CardTitle>{country.country}</CardTitle>
                {isExpanded && (
                    <ExpandedContent>
                        <CountryWeightInput
                            weight={country.internalWeight || 1}
                            onChange={(value) => onUpdate(country.id, 'internalWeight', value)}
                        />
                        <PassportList 
                            title="No Visa Required" 
                            fieldName="noVisaRequired" 
                            passportIds={country.noVisaRequired}
                            country={country}
                            passports={passports}
                            onPassportSelect={onPassportSelect}
                            onPassportRemove={onPassportRemove}
                        />
                        <PassportList 
                            title="Visa On Arrival" 
                            fieldName="visaOnArrival" 
                            passportIds={country.visaOnArrival}
                            country={country}
                            passports={passports}
                            onPassportSelect={onPassportSelect}
                            onPassportRemove={onPassportRemove}
                        />
                        <PassportList 
                            title="eVisa/ETA Required" 
                            fieldName="eVisaRequired" 
                            passportIds={country.eVisaRequired}
                            country={country}
                            passports={passports}
                            onPassportSelect={onPassportSelect}
                            onPassportRemove={onPassportRemove}
                        />
                        <PassportList 
                            title="Paper Visa Required" 
                            fieldName="paperVisaRequired" 
                            passportIds={country.paperVisaRequired}
                            country={country}
                            passports={passports}
                            onPassportSelect={onPassportSelect}
                            onPassportRemove={onPassportRemove}
                        />
                        <PassportList 
                            title="Restricted Passports" 
                            fieldName="restrictedPassports" 
                            passportIds={country.restrictedPassports}
                            country={country}
                            passports={passports}
                            onPassportSelect={onPassportSelect}
                            onPassportRemove={onPassportRemove}
                        />
                        <NotesSection>
                            <h6>Notes</h6>
                            <NotesTextArea
                                value={country.notes || ''}
                                onChange={(e) => onUpdate(country.id, 'notes', e.target.value)}
                                placeholder="Add notes about visa requirements, travel restrictions, or other important information..."
                            />
                        </NotesSection>
                        <ButtonContainer>
                            <PinkButtonContainer onClick={() => onDelete(country.id)}>
                                <EditIcon icon={['fas', 'trash']} />
                            </PinkButtonContainer>
                        </ButtonContainer>
                    </ExpandedContent>
                )}
            </CardContent>
        </CardContainer>
    );
};

export default CountryCard;