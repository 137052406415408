// visaWeightsManager.jsx
import React from 'react';
import styled from 'styled-components';
import { Input } from 'reactstrap';
import { Colors } from '../../../utils/budget-tool-specific';

const VisaWeightsManager = ({ weights, onWeightChange }) => {
  return (
    <WeightsContainer>
      <h5>Visa Requirement Weights</h5>
      <p className="text-muted">
        These weights determine how different visa requirements affect accessibility scores
      </p>
      <WeightGrid>
        <WeightRow>
          <WeightLabel>No Visa Required</WeightLabel>
          <WeightInput
            type="number"
            min="0"
            max="1000"
            value={weights.noVisa}
            onChange={(e) => onWeightChange('noVisa', parseFloat(e.target.value))}
          />
        </WeightRow>
        <WeightRow>
          <WeightLabel>Visa on Arrival</WeightLabel>
          <WeightInput
            type="number"
            min="0"
            max="1000"
            value={weights.visaOnArrival}
            onChange={(e) => onWeightChange('visaOnArrival', parseFloat(e.target.value))}
          />
        </WeightRow>
        <WeightRow>
          <WeightLabel>eVisa Required</WeightLabel>
          <WeightInput
            type="number"
            min="0"
            max="1000"
            value={weights.eVisa}
            onChange={(e) => onWeightChange('eVisa', parseFloat(e.target.value))}
          />
        </WeightRow>
        <WeightRow>
          <WeightLabel>Paper Visa Required</WeightLabel>
          <WeightInput
            type="number"
            min="0"
            max="1000"
            value={weights.paperVisa}
            onChange={(e) => onWeightChange('paperVisa', parseFloat(e.target.value))}
          />
        </WeightRow>
      </WeightGrid>
    </WeightsContainer>
  );
};

// Styled components
const WeightsContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const WeightGrid = styled.div`
  display: grid;
  gap: 15px;
  margin-top: 15px;
`;

const WeightRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 8px;
  border-radius: 4px;
  background: ${Colors.primaryLight}10;

  &:hover {
    background: ${Colors.primaryLight}20;
  }
`;

const WeightLabel = styled.label`
  margin: 0;
  font-weight: 500;
  color: ${Colors.primaryDark};
`;

const WeightInput = styled(Input)`
  width: 100px;
  text-align: right;
  &:focus {
    border-color: ${Colors.primaryAccent};
    box-shadow: 0 0 0 1px ${Colors.primaryAccent};
  }
`;

export default VisaWeightsManager;