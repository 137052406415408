import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { PrimaryButton, WhiteForm, Colors } from '../../../utils/budget-tool-specific';
import { listenToDocument, setDocument } from '../../../utils/firestore-functions';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import SettingsTransportation from './settings-transportation';
import SettingsAccommodations from './settings-accommodations';
import SettingsActivity from './settings-activity';
import SettingsMeals from './settings-meals';
import SettingsMisc from './settings-misc';
import SettingsBudget from './settings-budget';
import SettingsCountry from './settings-countries';
import SettingsPassport from './settings-passport';
import styled from 'styled-components';

const SettingsList = (props) => {
    const [data, setData] = useState();
    const [activeTab, setActiveTab] = useState('countries');
    const budgetDB = GetDB();

    const saveSettings = () => {
        setDocument(budgetDB, "admin", "calculations", data);
    }

    const setArea = (area, newData) => {
        let tempData = {...data};
        tempData[area] = newData;
        setData(tempData);
    } 

    useEffect(() => {
        var unsubscribe = listenToDocument(budgetDB, "admin", "calculations", setData);
        return unsubscribe;
    },[props]);

    const tabs = [
        { id: 'countries', label: 'Countries' },
        { id: 'passports', label: 'Passports' },
        { id: 'transportation', label: 'Transportation' },
        { id: 'accommodations', label: 'Accommodations' },
        { id: 'activities', label: 'Activities' },
        { id: 'meals', label: 'Meals' },
        { id: 'misc', label: 'Miscellaneous' },
        { id: 'budget', label: 'Budget' }
    ];

    const renderTabContent = () => {
        switch(activeTab) {
            case 'countries':
                return <SettingsCountry />;
            case 'passports':
                return <SettingsPassport />;
            case 'transportation':
                return <SettingsTransportation data={data?.transportationRow} setArea={setArea} />;
            case 'accommodations':
                return <SettingsAccommodations data={data?.accommodationRow} setArea={setArea} />;
            case 'activities':
                return <SettingsActivity data={data?.activityRow} setArea={setArea} />;
            case 'meals':
                return <SettingsMeals data={data?.mealsRow} setArea={setArea} />;
            case 'misc':
                return <SettingsMisc data={data?.miscRow} setArea={setArea} />;
            case 'budget':
                return <SettingsBudget data={data?.miscRow} setArea={setArea} />;
            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            <TabContainer>
                {tabs.map(tab => (
                    <Tab
                        key={tab.id}
                        active={activeTab === tab.id}
                        onClick={() => setActiveTab(tab.id)}
                    >
                        {tab.label}
                    </Tab>
                ))}
            </TabContainer>

            <ContentContainer>
                <WhiteForm>
                    {data !== undefined && renderTabContent()}
                </WhiteForm>

                {data !== undefined && activeTab !== 'countries' && activeTab !== 'passports' && (
                    <SaveButtonContainer>
                        <PrimaryButton onClick={saveSettings}>Save Settings</PrimaryButton>
                    </SaveButtonContainer>
                )}
            </ContentContainer>
        </React.Fragment>
    );
}
 
export default SettingsList;

const TabContainer = styled.div`
    display: flex;
    gap: 2px;
    padding: 0 15px;
    margin: 30px 0 -2px 0;  // Added top margin for spacing below header
    position: relative;
    z-index: 1;
`;

const Tab = styled.button`
    padding: 12px 24px;
    background: ${props => props.active ? Colors.primaryAccent : '#343434'};
    color: ${props => props.active ? Colors.primaryDark : '#ffffff'};
    border: none;
    border-radius: 8px 8px 0 0;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: ${props => props.active ? '600' : '400'};
    transform-origin: bottom;
    box-shadow: ${props => props.active ? '0 -2px 4px rgba(0,0,0,0.1)' : 'none'};
    
    &:hover {
        background: ${props => props.active ? Colors.primaryAccent : '#4a4a4a'};
        transform: translateY(-2px);
    }
    
    &:focus {
        outline: none;
    }
`;

const ContentContainer = styled.div`
    background: white;
    border-radius: 0 8px 8px 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SaveButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
`;