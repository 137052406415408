import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Spacer, HeaderTitle, WhiteForm } from '../../../utils/budget-tool-specific';
import PageTemplate from '../page-template';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import { collection, onSnapshot } from 'firebase/firestore';
import PassportGrid from './passportGrid';

const PassportComponent = () => {
    const [selectedPassports, setSelectedPassports] = useState([]);
    const [passports, setPassports] = useState([]);
    const db = GetDB();

    useEffect(() => {
        // Subscribe to passports collection
        const unsubscribePassports = onSnapshot(collection(db, 'Passports'), (snapshot) => {
            const passportData = [];
            snapshot.forEach((doc) => {
                passportData.push({ id: doc.id, ...doc.data() });
            });
            setPassports(passportData);
        });

        return () => {
            unsubscribePassports();
        };
    }, []);

    const handlePassportToggle = (passportId) => {
        setSelectedPassports(prev => {
            if (prev.includes(passportId)) {
                return prev.filter(id => id !== passportId);
            } else {
                return [...prev, passportId];
            }
        });
    };

    return (
        <React.Fragment>
            <Spacer height="60px"/>
            <HeaderTitle>Passport Selection</HeaderTitle>
            <WhiteForm>
                <PassportGrid 
                    passports={passports}
                    selectedPassports={selectedPassports}
                    onPassportToggle={handlePassportToggle}
                />
            </WhiteForm>
        </React.Fragment>
    );
};

const Passports = (props) => {
    if (props.collapsed) {
        return <PassportComponent />;
    } else {
        return <PageTemplate component={<PassportComponent/>} client={props.client}/>;
    }
};

export default Passports;