import React, { useState, useEffect } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { PrimaryButton } from '../../../utils/budget-tool-specific';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import { collection, setDoc, addDoc, updateDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { Container, ActionButtonContainer, SeedButton, CardsGrid } from '../passport/commonComponents';
import CountryCard from '../passport/countryCard';
import { countries as countriesData } from './countries';
import styled from 'styled-components';

const SettingsCountry = () => {
    const [countries, setCountries] = useState([]);
    const [expandedCountry, setExpandedCountry] = useState(null);
    const [passports, setPassports] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const db = GetDB();

    useEffect(() => {
        const unsubscribeCountries = onSnapshot(collection(db, 'Countries'), (snapshot) => {
            const countryData = [];
            snapshot.forEach((doc) => {
                countryData.push({ id: doc.id, ...doc.data() });
            });
            setCountries(countryData);
        });

        const unsubscribePassports = onSnapshot(collection(db, 'Passports'), (snapshot) => {
            const passportData = [];
            snapshot.forEach((doc) => {
                passportData.push({
                    value: doc.id,
                    label: doc.data().country
                });
            });
            setPassports(passportData);
        });

        return () => {
            unsubscribeCountries();
            unsubscribePassports();
        };
    }, []);

    const handleUpdate = async (countryId, field, value) => {
        try {
            if (field === 'internalWeight') {
                value = Math.max(0, Math.min(100, value));
            }
            
            await updateDoc(doc(db, 'Countries', countryId), {
                [field]: value
            });
        } catch (error) {
            console.error("Error updating country:", error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteDoc(doc(db, 'Countries', id));
        } catch (error) {
            console.error("Error deleting country:", error);
        }
    };

    const handleAddCountry = async () => {
        const newCountry = {
            country: 'New Country',
            noVisaRequired: [],
            visaOnArrival: [],
            eVisaRequired: [],
            paperVisaRequired: [],
            restrictedPassports: [],
            information: '',
            notes: '',
            internalWeight: 1
        };

        try {
            await addDoc(collection(db, 'Countries'), newCountry);
        } catch (error) {
            console.error("Error adding country:", error);
        }
    };

    const seedDatabase = async () => {
        try {
            for (const country of countriesData) {
                try {
                    const docRef = doc(db, 'Countries', country.code);
                    await setDoc(docRef, {
                        country: country.name,
                        noVisaRequired: [],
                        visaOnArrival: [],
                        eVisaRequired: [],
                        paperVisaRequired: [],
                        restrictedPassports: [],
                        information: `Capital: ${country.capital}\nRegion: ${country.region}\nCurrency: ${country.currency.name} (${country.currency.code})\nLanguage: ${country.language.name}`,
                        notes: '',
                        internalWeight: 1
                    });
                } catch (error) {
                    console.error(`Error adding ${country.name}:`, error);
                }
            }
            alert('Countries database seeded successfully!');
        } catch (error) {
            console.error('Error seeding database:', error);
            alert('Error seeding database. Check console for details.');
        }
    };

    const handlePassportSelect = (option, fieldName, country) => {
        if (!option) return;
        
        const currentList = country[fieldName] || [];
        if (!currentList.includes(option.value)) {
            handleUpdate(country.id, fieldName, [...currentList, option.value]);
        }
    };

    const handlePassportRemove = (passportId, fieldName, country) => {
        const currentList = country[fieldName] || [];
        handleUpdate(country.id, fieldName, currentList.filter(id => id !== passportId));
    };

    const toggleCountryExpansion = (countryId) => {
        setExpandedCountry(current => current === countryId ? null : countryId);
    };

    const filteredCountries = countries.filter(country =>
        country.country.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <h4>Country Database</h4>
                </Col>
                <Col className="text-end">
                    <ActionButtonContainer>
                        <PrimaryButton onClick={handleAddCountry}>
                            Add Country
                        </PrimaryButton>
                        {/*
                        <SeedButton onClick={seedDatabase}>
                        Seed Database
                        </SeedButton>
                        */}
                    </ActionButtonContainer>
                </Col>
            </Row>

            <SearchContainer>
                <SearchInput 
                    type="text"
                    placeholder="Search countries..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </SearchContainer>

            <CardsGrid>
                {filteredCountries.map((country) => (
                    <CountryCard
                        key={country.id}
                        country={country}
                        isExpanded={expandedCountry === country.id}
                        passports={passports}
                        onToggleExpand={toggleCountryExpansion}
                        onDelete={handleDelete}
                        onUpdate={handleUpdate}
                        onPassportSelect={(option, fieldName) => handlePassportSelect(option, fieldName, country)}
                        onPassportRemove={(passportId, fieldName) => handlePassportRemove(passportId, fieldName, country)}
                    />
                ))}
            </CardsGrid>
        </Container>
    );
};

const SearchContainer = styled.div`
    margin-bottom: 20px;
`;

const SearchInput = styled(Input)`
    max-width: 300px;
    border-radius: 4px;
    padding: 8px 12px;
    
    &:focus {
        border-color: ${props => props.theme.primaryAccent};
        box-shadow: 0 0 0 1px ${props => props.theme.primaryAccent};
        outline: none;
    }
`;

export default SettingsCountry;