// PassportComparison.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { Colors, PrimaryButton } from '../../../utils/budget-tool-specific';

const PassportComparison = ({ comparisonResults, onClose }) => {
    const [showAllResults, setShowAllResults] = useState(false);

    return (
        <ResultsContainer>
            <ResultsHeader>
                <h4>Best Destinations for Selected Passports</h4>
                <CloseButton onClick={onClose}>×</CloseButton>
            </ResultsHeader>
            <ResultsGrid>
                {comparisonResults
                    .slice(0, showAllResults ? undefined : 10)
                    .map((result, index) => (
                    <ResultCard key={index} accessibilityScore={result.accessibilityScore}>
                        <CountryName>
                            {result.country}
                            <AccessibilityIndicator>
                                {result.noVisaCount > 0 && (
                                    <AccessCount color="#4CAF50">
                                        {result.noVisaCount}/{result.totalPassports} No Visa
                                    </AccessCount>
                                )}
                                {result.visaOnArrivalCount > 0 && (
                                    <AccessCount color="#2196F3">
                                        {result.visaOnArrivalCount}/{result.totalPassports} VOA
                                    </AccessCount>
                                )}
                                {result.eVisaCount > 0 && (
                                    <AccessCount color="#FFC107">
                                        {result.eVisaCount}/{result.totalPassports} eVisa
                                    </AccessCount>
                                )}
                                {result.paperVisaCount > 0 && (
                                    <AccessCount color="#FF5722">
                                        {result.paperVisaCount}/{result.totalPassports} Paper
                                    </AccessCount>
                                )}
                            </AccessibilityIndicator>
                        </CountryName>
                    </ResultCard>
                ))}
            </ResultsGrid>
            {comparisonResults.length > 10 && (
                <ShowMoreButton onClick={() => setShowAllResults(!showAllResults)}>
                    {showAllResults ? 'Show Less' : `Show ${comparisonResults.length - 10} More Countries`}
                </ShowMoreButton>
            )}
        </ResultsContainer>
    );
};

// Styled components for PassportComparison...
const ResultsContainer = styled.div`
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ResultsHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    
    h4 {
        margin: 0;
        color: ${Colors.primaryDark};
    }
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    color: ${Colors.primaryDark};
    cursor: pointer;
    padding: 0 8px;
    
    &:hover {
        color: ${Colors.primaryAccent};
    }
`;

const ResultsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
`;

const ResultCard = styled.div`
    padding: 15px;
    background: #f8f9fa;
    border-radius: 6px;
    border-left: 4px solid ${props => {
        if (props.accessibilityScore >= 1000) return '#4CAF50';
        if (props.accessibilityScore >= 100) return '#2196F3';
        if (props.accessibilityScore >= 10) return '#FFC107';
        return '#FF5722';
    }};
`;

const CountryName = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${Colors.primaryDark};
`;

const AccessibilityIndicator = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
`;

const AccessCount = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.color};
    background: ${props => props.color}10;
    padding: 2px 6px;
    border-radius: 4px;
    border: 1px solid ${props => props.color}40;
`;

const ShowMoreButton = styled(PrimaryButton)`
    margin: 20px auto 0;
    display: block;
`;

export default PassportComparison;