import React from 'react';
import Select from 'react-select';
import { VisaSection, PassportTags, Tag, TagDelete } from './commonComponents';
import { Colors } from '../../../utils/budget-tool-specific';

const PassportList = ({ title, fieldName, passportIds, country, passports, onPassportSelect, onPassportRemove }) => {
    // Get all passports that are already used in any category
    const getUsedPassports = (country) => {
        const usedPassports = [
            ...(country.noVisaRequired || []),
            ...(country.visaOnArrival || []),
            ...(country.eVisaRequired || []),
            ...(country.paperVisaRequired || []),
            ...(country.restrictedPassports || [])
        ];
        return usedPassports;
    };

    // Filter out passports that are already used in any category
    const usedPassports = getUsedPassports(country);
    const availablePassports = passports.filter(passport => 
        !usedPassports.includes(passport.value) || 
        (passportIds && passportIds.includes(passport.value))
    );

    const getPassportNames = (passportIds) => {
        return passportIds?.map(id => 
            passports.find(p => p.value === id)?.label
        ).filter(Boolean) || [];
    };

    return (
        <VisaSection>
            <h6>{title}</h6>
            <Select
                value={null}
                onChange={(option) => onPassportSelect(option, fieldName)}
                options={availablePassports}
                placeholder="Add passport..."
                styles={{
                    control: (base) => ({
                        ...base,
                        background: 'white',
                        borderColor: Colors.primaryLight,
                    }),
                    menu: (base) => ({
                        ...base,
                        background: 'white',
                    }),
                    option: (base, state) => ({
                        ...base,
                        backgroundColor: state.isFocused ? Colors.primaryLight : 'white',
                        color: Colors.primaryDark,
                        '&:hover': {
                            backgroundColor: Colors.primaryLight,
                        },
                    }),
                    singleValue: (base) => ({
                        ...base,
                        color: Colors.primaryDark,
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#666',
                    }),
                }}
            />
            <PassportTags>
                {getPassportNames(passportIds).map((name, index) => (
                    <Tag key={index}>
                        {name}
                        <TagDelete onClick={() => onPassportRemove(passportIds[index], fieldName)}>
                            ×
                        </TagDelete>
                    </Tag>
                ))}
            </PassportTags>
        </VisaSection>
    );
};

export default PassportList;