// PassportGrid.jsx
import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import { Colors, PrimaryButton } from '../../../utils/budget-tool-specific';
import styled from 'styled-components';
import { GetDB } from '../../../utils/database/firebase-budget-tool';
import { collection, onSnapshot } from 'firebase/firestore';
import PassportComparison from './passportComparison';
import { comparePassports } from './comparePassports';

const PassportGrid = ({ passports, selectedPassports, onPassportToggle }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [showResults, setShowResults] = useState(false);
    const [comparisonResults, setComparisonResults] = useState([]);
    const [countries, setCountries] = useState([]);
    const db = GetDB();

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, 'Countries'), (snapshot) => {
            const countryData = [];
            snapshot.forEach((docSnapshot) => {
                countryData.push({ id: docSnapshot.id, ...docSnapshot.data() });
            });
            setCountries(countryData);
        });

        return () => unsubscribe();
    }, []);

    const handleCompare = () => {
        const results = comparePassports(passports, selectedPassports, countries);
        setComparisonResults(results);
        setShowResults(true);
    };

    const filteredPassports = passports.filter(passport =>
        passport.country.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const unselectedPassports = filteredPassports.filter(
        passport => !selectedPassports.includes(passport.id)
    );

    const selectedPassportObjects = passports.filter(
        passport => selectedPassports.includes(passport.id)
    );

    return (
        <Container>
            <TopSection>
                <SearchInput 
                    type="text"
                    placeholder="Search countries..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                <PrimaryButton 
                    onClick={handleCompare}
                    disabled={selectedPassports.length === 0}
                >
                    Compare Selected Passports
                </PrimaryButton>
            </TopSection>

            {showResults && (
                <PassportComparison 
                    comparisonResults={comparisonResults}
                    onClose={() => setShowResults(false)}
                />
            )}

            <GridContainer>
                <GridColumn>
                    <ColumnTitle>Available Passports</ColumnTitle>
                    <GridContent>
                        {unselectedPassports.map(passport => (
                            <PassportCard 
                                key={passport.id}
                                onClick={() => onPassportToggle(passport.id)}
                                isSelected={false}
                            >
                                {passport.country}
                            </PassportCard>
                        ))}
                    </GridContent>
                </GridColumn>

                <GridColumn>
                    <ColumnTitle>Selected Passports</ColumnTitle>
                    <GridContent>
                        {selectedPassportObjects.map(passport => (
                            <PassportCard 
                                key={passport.id}
                                onClick={() => onPassportToggle(passport.id)}
                                isSelected={true}
                            >
                                {passport.country}
                            </PassportCard>
                        ))}
                    </GridContent>
                </GridColumn>
            </GridContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const TopSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const SearchInput = styled(Input)`
    max-width: 300px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
`;

const GridColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const ColumnTitle = styled.h5`
    color: ${Colors.primaryDark};
    margin: 0;
`;

const GridContent = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    padding: 10px;
    background: ${Colors.primaryLight}20;
    border-radius: 8px;
`;

const PassportCard = styled.div`
    background: ${props => props.isSelected ? Colors.primaryAccent : Colors.primaryDark};
    color: ${props => props.isSelected ? Colors.primaryDark : Colors.primaryAccent};
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    text-align: center;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;

    &:hover {
        background: ${props => props.isSelected ? Colors.primaryAccent : `${Colors.primaryDark}dd`};
    }
`;

export default PassportGrid;