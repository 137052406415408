import { doc, onSnapshot, arrayUnion } from "firebase/firestore";

export const listenToCollection = (db, collection, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).where("id","!=","").onSnapshot((docs) => {
        if (!docs === undefined || docs === null){
            throw "Collection not found! Collection: " + collection;
        }
        else{
            var values = [];
            docs.forEach((doc) => {
                values.push(doc.data());
            })
            successCallback(values);
        }
    })
    return unsubscribe;
}; 

export const listenToDocument = (db, collectionName, documentName, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collectionName).doc(documentName).onSnapshot(document => {
        if (document !== undefined){
            console.log("Success!" + document.data())
            successCallback(document.data());
        }
        else{
            console.log("We are undefined baby!");
            if (failureCallback !== undefined){
                failureCallback();
            }
        }
    })
    return unsubscribe;
}

export const listenToDocumentV2 = (db, collectionName, documentName, successCallback, failureCallback) => {
    var unsubscribe = onSnapshot(doc(db, collectionName + "/" + documentName), (document) => {
        if (document !== undefined){
            successCallback(document.data());
        }
        else{
            console.log("We are undefined baby!");
            if (failureCallback !== undefined){
                failureCallback();
            }
        }
    })
    return unsubscribe;
}

export const listenToDocumentV3 = (db, collectionName, documentName, successCallback, failureCallback) => {
    var unsubscribe = onSnapshot(doc(db, collectionName, documentName), (document) => {
        if (document !== undefined){
            successCallback(document.data());
        }
        else{
            console.log("We are undefined baby!");
            if (failureCallback !== undefined){
                failureCallback();
            }
        }
    })
    return unsubscribe;
}

export const listenToDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + document;
        }
        else{
            const value = document.data()[property];
            if (successCallback) {
                successCallback(value);
            }
        }
    })
    return unsubscribe;
};

export const listenToNestedDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = db.collection(collection).doc(document).onSnapshot((document) => {
        if (!document.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + document;
        }
        else{
            const value = document.data()[property];
            successCallback(value);
        }
    })
    return unsubscribe;
};

export const listenToDocumentPropertyV2 = (db, collection, document, property, successCallback, failureCallback) => {
    var unsubscribe = onSnapshot(doc(db, collection + "/" + document), (document) => {
        if (!document.exists){
            throw "Document not found! Collection: " + collection + ", Document: " + document;
        }
        else{
            const value = document.data()[property];
            successCallback(value);
        }
    })
    return unsubscribe;
};

export const updateDocumentProperty = (db, collection, document, property, value, successCallback, failureCallback) => {
    db.collection(collection).doc(document).update({
        [property]: value
    })
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const updateDocumentArray = (db, collection, document, array, value, successCallback, failureCallback) => {
    db.collection(collection).doc(document).update({
        [array]: arrayUnion(value)
    })
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const setDocument = (db, collection, document, data, successCallback, failureCallback) => {
    db.collection(collection).doc(document).set(
        data
    )
    .then(() => {
        if (successCallback !== undefined) {
            successCallback();
        }
    })
    .catch((error) => {
        if(failureCallback){
            console.log(error);
            failureCallback(error);
        }
    })
};

export const deleteDocument = (db, collection, document, successCallback, failureCallback) => {
    db.collection(collection).doc(document).delete().then(() => {
        console.log("Document successfully deleted!");
        if (successCallback !== undefined) {
            successCallback();
        }
    }).catch((error) => {
        console.error("Error removing document: ", error);
        if(failureCallback){
            console.error("Error removing document: ", error);
            failureCallback(error);
        }
    });
};

export const deleteDocumentProperty = (db, collection, document, property, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[property];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const deleteNestedDocumentProperty = (db, collection, document, propertyParent, propertyChild, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[propertyParent][propertyChild];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};

export const deleteNestedDocumentProperty3 = (db, collection, document, propertyParent, propertyChild, propertyGrandchild, propertyGGChild, successCallback, failureCallback) => {
    return db.runTransaction((transaction) => {
        return transaction.get(db.collection(collection).doc(document)).then((doc) => {
            var data = doc.data();
            delete data[propertyParent][propertyChild][propertyGrandchild][propertyGGChild];
            transaction.update(db.collection(collection).doc(document), data);
        });
    });
};
