import React, {useState} from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import SettingsInput from '../budget-tool/input-components/settings-input';
import { CollapseButton, ButtonContainer } from '../../../utils/budget-tool-specific';

const SettingsBudget = (props) => {

    const [opened, setOpened] = useState(false);
    
    const normalStyle = {
        transition: '1s, transform 0.5s',
    };
      
    const transformedStyle = {
        transition: '1s, transform 0.5s',
        transform: 'rotate(180deg)',
    };

    return ( 
        <React.Fragment>
            <div style={{display: "flex", textAlign: "center", alignItems: "center", verticalAlign: "middle"}}>
                <ButtonContainer onClick={() => {setOpened(!opened)}}>
                    <CollapseButton icon={['fas', 'chevron-down']} style={opened ? transformedStyle : normalStyle}/> 
                </ButtonContainer>
                <h4>Budget Tool Settings</h4>
            </div>
            <Collapse isOpen={opened}>
            {props.data !== undefined && Object.entries(props.data).map((el, index) => {
                console.log(el);
                return(
                    <div key={index}>
                        {Object.entries(el[1]).sort().map((e,i) => {
                            return(
                                <div key={i}>
                                    {(e[0] === "3 stars") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "4 stars") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                    {(e[0] === "5 stars") &&
                                        <Row key={e[0]}>
                                        <Col>
                                            <p>{e[0]}</p>
                                        </Col>
                                        <Col>
                                        <SettingsInput 
                                            type="number"
                                            setArea={props.setArea}
                                            data={props.data}
                                            area={"miscRow"}
                                            indices={[el[0].toString(), e[0].toString()]}
                                            />
                                        </Col>
                                    </Row>}
                                </div>
                            )
                        })}
                    </div>
                )
            })}
            </Collapse>
        </React.Fragment>
    )
}
 
export default SettingsBudget;