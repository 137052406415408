// comparePassports.js
export const comparePassports = (passports, selectedPassports, countries) => {
    const selectedPassportData = passports.filter(p => selectedPassports.includes(p.id));
    
    const results = countries.map(country => {
        const noVisa = selectedPassportData.filter(p => 
            country.noVisaRequired?.includes(p.id)
        );
        const visaOnArrival = selectedPassportData.filter(p => 
            country.visaOnArrival?.includes(p.id)
        );
        const eVisa = selectedPassportData.filter(p => 
            country.eVisaRequired?.includes(p.id)
        );
        const paperVisa = selectedPassportData.filter(p => 
            country.paperVisaRequired?.includes(p.id)
        );

        const noVisaCount = noVisa.length;
        const visaOnArrivalCount = visaOnArrival.length;
        const eVisaCount = eVisa.length;
        const paperVisaCount = paperVisa.length;

        const accessibilityScore = 
            (noVisaCount * 1000) + 
            (visaOnArrivalCount * 100) + 
            (eVisaCount * 10) + 
            (paperVisaCount * 1);

        if (noVisaCount + visaOnArrivalCount + eVisaCount + paperVisaCount > 0) {
            return {
                country: country.country,
                noVisa: noVisa.map(p => p.country),
                visaOnArrival: visaOnArrival.map(p => p.country),
                eVisa: eVisa.map(p => p.country),
                paperVisa: paperVisa.map(p => p.country),
                totalPassports: selectedPassports.length,
                noVisaCount,
                visaOnArrivalCount,
                eVisaCount,
                paperVisaCount,
                accessibilityScore
            };
        }
        return null;
    }).filter(Boolean);

    return results.sort((a, b) => b.accessibilityScore - a.accessibilityScore);
};