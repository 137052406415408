import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Switch, Route,} from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faSkullCrossbones, faTimes, faMoneyBill, faGlobeAmericas, faBed, faPlane, 
  faCar, faUtensils, faPlus, faStickyNote, faPen, faClipboard, faShieldAlt, faTable, faGripVertical, faCogs, faSync, faGhost, faTree, faClipboardList, 
  faPassport} from '@fortawesome/free-solid-svg-icons'
import BudgetApp from "./components/budget-app/budget-app";
import BudgetViewer from './components/budget-app/budget-viewer/budget-viewer';

library.add(fab, faCheckSquare, faCoffee, faEdit, faRedo, faTrash, faChevronDown, faChevronUp, faUserPlus, faExclamationCircle, faCopy, faAnchor, faSpaceShuttle,
  faBiohazard, faSkull, faBalanceScale, faMinusSquare, faToggleOn, faToggleOff, faSkullCrossbones, faTimes, faMoneyBill, faGlobeAmericas, faBed, faPlane, faCar, 
  faUtensils, faPlus, faStickyNote, faPen, faClipboard, faShieldAlt, faTable, faGripVertical, faCogs, faSync, faGhost, faTree, faClipboardList, faPassport);

class App extends Component {
  constructor() {
    super();
    this.state = {
      name: 'React'
    };
  }

  getEnvironment(){
    var loc = window.location.href;
    if (loc.includes("localhost")){
      return "cdn.test.deleptual.ca/dev";
    }
    else if (loc.includes("test.deleptual")){
      return "cdn.test.deleptual.ca/dev";
    }
    else if (loc.includes("deleptual")){
      return "cdn.test.deleptual.ca/staging";
    }
    else if (loc.includes("moniker-virtual")){
      return "cdn.moniker-virtual.com";
    }
  };

  render() {
    return (
      <Router>
        <div>
          <Switch>
            <Route title="Budget" path="/budget-app" children={<BudgetApp environment={this.getEnvironment()}/>} />
            <Route title="Budget" path="/budget-view/:attendees/:length/:experience/:flight/:goal/:alcohol/:ground/:adds/:occupancy/:inclusive" children={<BudgetViewer environment={this.getEnvironment()}/>} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
